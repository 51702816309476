import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DaysBetween } from "./DaysBetween";
import "./App.css";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = React.useMemo(() => {
    const theme = createTheme({
      palette: {
        mode: prefersDarkMode ? "dark" : "light",
      },
    });
    theme.typography.emphasis = {
      fontSize: "16rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "7.5rem",
      },
    };
    return responsiveFontSizes(theme);
  }, [prefersDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="App">
          <DaysBetween />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
