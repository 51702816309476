import React, { useState } from "react";
import { differenceInCalendarDays, differenceInBusinessDays } from "date-fns";
import { TextField, Typography } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker } from "@mui/x-date-pickers";
import styled from "@emotion/styled";

const ResultGrid = styled.div`
  display: grid;
  row-gap: 0;
  margin-bottom: 1em;

  > :last-child {
    margin-top: -1.3em;
  }
`;

const PickerGrid = styled.div`
  display: grid;
  row-gap: 1em;
  max-width: 260px;
`;

const DaysBetweenFormGroup = styled(FormGroup)``;

const DATE_FORMAT = "dd/MM/yyyy";

export function DaysBetween() {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isWeekdaysOnly, setIsWeekdaysOnly] = useState<boolean>(false);
  const updateFn =
    (mutatorFn: React.Dispatch<React.SetStateAction<Date>>) =>
    (newDate: string | null) =>
      newDate && mutatorFn(new Date(newDate));
  const differenceFn = isWeekdaysOnly
    ? differenceInBusinessDays
    : differenceInCalendarDays;
  const difference = differenceFn(endDate, startDate);

  return (
    <div className="Container">
      <ResultGrid>
        <Typography align="center" variant="emphasis" letterSpacing={"-5px"}>
          {difference}
        </Typography>
        <Typography align="center" fontSize={"2rem"} variant="h2">
          {difference === 1 ? "day" : "days"}
        </Typography>
      </ResultGrid>
      <PickerGrid>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={updateFn(setStartDate)}
          inputFormat={DATE_FORMAT}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={updateFn(setEndDate)}
          inputFormat={DATE_FORMAT}
          renderInput={(params) => <TextField {...params} />}
        />
        <DaysBetweenFormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => setIsWeekdaysOnly(event.target.checked)}
                value={isWeekdaysOnly}
              />
            }
            label="Only weekdays"
          />
        </DaysBetweenFormGroup>
      </PickerGrid>
    </div>
  );
}
